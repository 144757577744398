// Build information, automatically generated by `ng-info`
const build = {
    version: "4.11.0",
    timestamp: "Sat Mar 29 2025 13:45:06 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "master",
        hash: "f94515",
        fullHash: "f9451535d047a72ab247de5434c21f0fa51a3b62"
    }
};

export default build;